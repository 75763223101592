

function Header() {
    return (
      <>
        <div className="dadJokesHeader">
            <h1 className="headerH1">Dad Jokes</h1>
        </div>
      </>
    );
  }
  
  export default Header;